import Vue from 'vue'
import App from './App.vue'
import 'normalize.css'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'
import element from './plugins/elementui/index'
import './assets/styles/index.css'

Vue.config.productionTip = false
Vue.prototype.baseUrl = 'https://img.uxiaod.com/gw/'
Vue.use(element, { size: 'small', zIndex: 3000 })

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
